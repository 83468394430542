import Color from "color";
import styled, { css } from "styled-components";
import { buildColor, isThemeColor } from "../../Css";
export const StyledButton = styled.button.withConfig({
    shouldForwardProp: (prop, el) => {
        return [
            "active",
            // "disabled",
            "outline",
            "borderless",
            "variant",
            "size",
        ].includes(prop) === false;
    }
}) `
	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 0px;
	${props => props.active ? css `box-shadow: inset 0 0 1em ${props.theme.color.cancelled[300].main};` : ""}

	${props => {
    switch (props.size) {
        case "sm": return css `
				padding: 5px 10px;
				font-size: 12px;
				line-height: 1.5;
			`;
        case "lg": return css `
				padding: 10px 16px;
				font-size: 18px;
				line-height: 1.3333333;
			`;
        case "xs": return css `
				padding: 1px 5px;
				font-size: 12px;
				line-height: 1.5;
			`;
        case "md": return css `
				padding: 6px 12px;
				font-size: 14px;
				line-height: 1.42857143;
			`;
        default: return css `
				padding: 6px 12px;
				font-size: 14px;
				line-height: 1.42857143;
			`;
    }
}}

	${props => {
    let color;
    let hover;
    let active;
    let shadow;
    let border;
    if (props.variant === "link") {
        return css `
				color: #337ab7;
				background-color: transparent;
				border-color: transparent;
				${e => props.disabled ? "cursor: not-allowed !important; opacity: .65;" : ""}
				${e => props.disabled ? "" : `
					&:hover {
						color: #23527c;
						background-color: transparent;
						border-color: transparent;
					}
					&:focus {
						color: #23527c;
						background-color: transparent;
						border-color: transparent;
					}
					&:active {
						color: #23527c;
						background-color: transparent;
						border-color: transparent;
					}
				`}
			`;
    }
    else if (isThemeColor(props.variant)) {
        color = props.theme.color[props.variant][500];
        hover = props.theme.color[props.variant][600];
        active = props.theme.color[props.variant][700];
        border = props.theme.color[props.variant][600].main;
        shadow = props.theme.color[props.variant][800];
    }
    else if (!props.variant || props.variant === "default") {
        const fullColor = buildColor(props.theme.kind, new Color(props.theme.kind === "light" ? "#fff" : "#000"));
        color = fullColor[500];
        hover = {
            main: props.theme.defaults.hover,
            font: "#fff",
        };
        border = props.theme.defaults.borderColor;
        active = fullColor[600];
        shadow = fullColor[800];
    }
    else {
        const fullColor = buildColor(props.theme.kind, new Color(props.variant));
        color = fullColor[500];
        hover = fullColor[600];
        active = fullColor[700];
        border = fullColor[600].main;
        shadow = fullColor[800];
    }
    if (props.outline) {
        return css `
				background: transparent;
				border: 1px solid ${color.main};
				color: ${color.main};
				${e => props.disabled ? "cursor: not-allowed !important; opacity: .65;" : ""}
				${e => props.disabled ? "" : `
					&:hover {
						background: ${color.main};
						color: ${color.font};
					}
					&:focus {
						background: ${color.main};
						color: ${color.font};
					}
					transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
				`}
			`;
    }
    else {
        return css `
				border: 1px solid ${border};
				background: ${color.main};
				${e => props.disabled ? "cursor: not-allowed !important; opacity: .65;" : ""}
				${e => props.disabled ? "" : `
					&:hover {
						background: ${hover.main};
					}
					&:active {
						background: ${active.main};
						box-shadow: inset 0 0 1em ${shadow.main};
					}
				`}
				color: ${color.font};
			`;
    }
}}

	${(props) => props.borderless ? css `border: 1px solid transparent;` : ""}
`;
const InternalButton = StyledButton;
const ButtonGroup = styled.div `
	display: inline-flex;
	vertical-align: middle;
	& > ${StyledButton} {
		margin-right: -1px;
	}
	& > ${StyledButton}:last-child {
		margin-right: 0;
	}
`;
export const Button = Object.assign(InternalButton, {
    Group: ButtonGroup,
});
Button.defaultProps = {
    type: "button",
};
