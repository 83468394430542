import Color from "color";
import { omit } from "lodash";
import React from "react";
import styled from "styled-components";
import { useThemeColor } from "../../Css";
import { Flex, FlexItem } from "../Flex";
import { Label } from "../Label";
import { Text } from "../Text";
const WorkflowWidgetCtx = React.createContext({ condensed: false, border: "top" });
const InternalWorkflowWidget = function InternalWorkflowWidget({ condensed = false, border = "top", noGap = false, variant = "base", style, children, }) {
    return (React.createElement(WorkflowWidgetCtx.Provider, { value: {
            border,
            condensed,
            noGap,
            style,
        } },
        React.createElement(WorkflowWidgetWrapper, { wrap: true, style: style, className: `${variant ?? ""} ${noGap ? "no-gap" : ""}` }, children)));
};
const ActiveCtx = React.createContext(null);
const WorkflowWidgetLevel = function WorkflowWidgetLevel(props) {
    const { condensed } = React.useContext(WorkflowWidgetCtx);
    const color = useThemeColor(props.variant, "#ddd");
    return (React.createElement(ActiveCtx.Provider, { value: props.isActive ?? null },
        React.createElement(WorkflowWidgetContainer, { column: true, style: props.style },
            condensed ? (React.createElement(CondensedBar, { style: { backgroundColor: color[500].main } })) : (React.createElement(Label, { fullWidth: true, variant: props.variant, style: { lineHeight: ".25rem" } },
                React.createElement(Text, { size: "xs" }, props.title))),
            React.createElement(LevelStepWrapper, null, props.children))));
};
export const CondensedBar = styled(FlexItem) `

`;
export const LevelStepWrapper = styled(Flex) `

`;
const Border = styled.div `

width: 100%;
background: ${p => p.background};
`;
export const WorkflowWidgetContainer = styled(Flex) `
	gap: 2px;
`;
const WorkflowWidgetStep = function WorkflowWidgetStep(props) {
    const style = { ...(props.style ?? {}) };
    const color = useThemeColor(props.variant, "#ddd");
    const pointerColor = useThemeColor(props.pointerVariant, "#ddd");
    const providedIsActive = React.useContext(ActiveCtx);
    const isActive = props.isActive ?? providedIsActive;
    const { condensed, border } = React.useContext(WorkflowWidgetCtx);
    const bg = new Color(color[500].main).mix(new Color("#fff"), .8).hex();
    const pointerBg = new Color(pointerColor[500].main).mix(new Color("#fff"), .8).hex();
    const pointerHover = new Color(pointerBg).darken(.1).hex();
    const hover = new Color(bg).darken(.1).hex();
    let passedProps = omit(props, "variant", "isActive", "children", "ref");
    // this is dirty but 'Step' is a div and doesn't honor 'disabled'
    if (props.disabled) {
        passedProps = omit(passedProps, "onClick");
        style.cursor = "default";
    }
    // set cursor if the button is clickable
    else if (passedProps.onClick) {
        style.cursor = "pointer";
    }
    return (React.createElement(StepContainer, { column: true },
        border === "top" && !condensed && React.createElement(Border, { background: color[500].main }),
        React.createElement(Step, { style: style, isactive: isActive, bg: bg, condensed: condensed, hover: hover, ...passedProps, pointerhover: props.pointerVariant ? pointerHover : undefined, pointerbg: props.pointerVariant ? pointerBg : undefined }, props.children),
        border === "bottom" && !condensed && React.createElement(Border, { background: color[500].main })));
};
const InternalLabel = styled(Label) `
	padding: .25rem .5rem;
`;
const Step = styled.div `
	height: 100%;
	padding-right: .4em;
	position: relative;
	// display: flex;
	margin: auto;
	align-content: center;
	&:hover {
		background: ${p => p.hover};
		&:after {
			background: ${p => p.pointerhover || p.hover};
		}
	}

	opacity: ${props => props.isactive ? 1 : .5};
	clip-path: polygon(
		0 0,
		100% 0,
		calc(100% + 1em) 50%,
		100% 100%,
		0 100%,
		1em 50%,
		0 0
	);
	// flex-direction: column;
	padding-left: 1.5em;
	background: ${p => p.bg};

	&::after {
		content: "";
		position: absolute;
        width: 1em;
        height: 100%;
        background-color: ${p => p.pointerbg || p.bg};
        top: 0;
        right: -0.99em;
        clip-path: polygon(0 0, 100% 50%, 0 100%);
	}
`;
const StepContainer = styled(Flex) ``;
export const WorkflowWidget = Object.assign(InternalWorkflowWidget, {
    Level: WorkflowWidgetLevel,
    Step: Object.assign(WorkflowWidgetStep, {
        Label: InternalLabel,
    }),
});
export const WorkflowWidgetWrapper = styled(Flex) `
&.base {
	gap: 3px;
	&.no-gap {
		gap: 0px;
	}
	${CondensedBar} {
		height: 2px;
	}
	${Border} {
		height: 2px;
	}

	${LevelStepWrapper} {
		gap: 2px;
	}
}

&.grid {
	gap: 1px;
	${CondensedBar} {
		height: 1px;
	}
	${Border} {
		height: 1px;
	}
	&.no-gap {
		gap: 0px;
	}
	${WorkflowWidgetContainer} {
		gap: 0px;
	}
	${LevelStepWrapper} {
		gap: 1px;
	}
}

& > :nth-child(1) {
	/* ${StepContainer}:first-child {
		} */
	${Step} {
		padding-left: .4em;
		clip-path: unset;
	}
}
`;
