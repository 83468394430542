import React from "react";
import moment from "moment-timezone";
import { UiContext } from "../../FpWebUiProvider";
const TimezoneContext = React.createContext(undefined);
export const TimezoneProvider = function TimezoneProvider({ timezone, children }) {
    return React.createElement(TimezoneContext.Provider, { value: timezone }, children);
};
export const useTimezone = function useTimezone(providedTz) {
    const tzCtx = React.useContext(TimezoneContext);
    const { formatter } = React.useContext(UiContext);
    let retTz = (() => {
        if (providedTz)
            return providedTz;
        if (!tzCtx)
            return formatter.options.tz;
        return tzCtx;
    })();
    if (retTz === "system") {
        retTz = moment.tz.guess();
    }
    return retTz;
};
