import { TimeManagementRecord, TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../FleetplanApp";


export class TimeManagementStore {
	private getEmptyEntry(id: number, year: number): TimeManagementRecord {
		return {
			id,
			year,
			data: {
				year,
				tdt_start: +`${year}0101`,
				tdt_year: {
					tdt_months: Array.from({ length: 12 }).reduce<TimeManagementRecord["data"]["tdt_year"]["tdt_months"]>((e: TimeManagementRecord["data"]["tdt_year"]["tdt_months"], _, idx) => {
						return {
							...e,
							[idx]: {
								tdt_m_g: null,
							}
						};
					}, {} as TimeManagementRecord["data"]["tdt_year"]["tdt_months"]),
					tdt_y_g: null,
					tdt_y_n: null,
				},
				tdt_contracts: [{
					contract: {
						valid_from: +`${year}0101`,
					},
					tdt: {
						tdt_y_n: null,
						vacationPerYearInDays: null,
						workdaysPerWeek: null,
						tdt_year: {
							holidayPerYearInDays_n: null,
							tdt_y_g: null,
							tdt_d_n: null,
							tdt_w_n: null,
						}
					}
				}]
			}
		};
	}
	constructor(protected app: FleetplanApp){}
	private timeManagementMap = new Map<number, TimeManagement>();
	private didLoad = new Set<`${number},${number}`>(); /** year, dscaids */

	/**
	 * 
	 * @param ids contact ids
	 * @param year the year to load
	 */
	private async load(ids: number[], years = [ new Date().getFullYear() ], signal?: AbortSignal): Promise<TimeManagementRecord[]> {
		const requiredToLoad = new Set<number>();
		const requiredYears = new Set<number>();
		// dedupe request and compare wich years we already loaded
		// if at least one dscaid is missing in one year, we load the entire year for every contact
		for(const id of new Set(ids)) {
			for(const year of new Set(years)) {
				if(this.didLoad.has(`${year},${id}`) === false) {
					requiredYears.add(year);
					requiredToLoad.add(id);
				}
			}
		}
		// what data did we already loaded?
		const loadedTimeManagement = (requiredToLoad.size > 0 && requiredYears.size > 0) ? await this.app.fpDirClient.getTimeManagement(this.app.ctx.dscid, { ids: Array.from(requiredToLoad), years: Array.from(requiredYears) }, signal) : [];
		const loadedTimeManagementMap = new Map(loadedTimeManagement.map(e => ([ `${e.year},${e.id}`, e ])));
		if(signal && signal.aborted) return [];
		requiredToLoad.forEach(dscaid => requiredYears.forEach(year => this.didLoad.add(`${year},${dscaid}`)));
		const response: TimeManagementRecord[] = [];
		for(const contactId of requiredToLoad) {
			for(const year of requiredYears) {
				const item = loadedTimeManagementMap.get(`${year},${contactId}`);
				response.push(item ?? this.getEmptyEntry(contactId, year));
			}
		}
		return response;
	}

	public async getIdList(ids: number[], year = [ new Date().getFullYear() ], signal?: AbortSignal): Promise<TimeManagement[]> {
		if(!this.app.ctx.hasModule("time-management")) return [];
		// permission check
		const targetIds: number[] = [];
		for(const id of ids) {
			if(this.app.ctx.hasPermission("dscaid.time-management", id, 1)) targetIds.push(id);
		}
		const loadedEntries = await this.load(targetIds, year, signal);
		if(signal && signal.aborted) return [];
		// update entries
		for(const entry of loadedEntries) {
			let timeManagement = this.timeManagementMap.get(entry.id);
			if(timeManagement == null) {
				timeManagement = TimeManagement.from(entry);
				this.timeManagementMap.set(entry.id, timeManagement);
			} else {
				timeManagement.addRecord(entry);
			}
		}

		return ids.map(e => this.timeManagementMap.get(e)).filter(Boolean);
	}

	public flush(): void {
		this.didLoad.clear();
		this.timeManagementMap.clear();
	}
}


export class TimeManagement {


	private years = new Map<number, TimeManagementRecord>();
	private validity: Array<TimeManagementRecord["data"]["tdt_contracts"][0] & { validity: TimeSpan }>;
	private runIndex(): void {
		const validity: Array<(TimeManagementRecord["data"]["tdt_contracts"][0] & { validity: TimeSpan })> = [];
		for(const [ _, row ] of this.years) {
			validity.push(
				...row.data.tdt_contracts.map(contract => ({ ...contract, validity: new TimeSpan(
					DateTime.fromFormat(contract.contract.valid_from.toString(), "yyyyMMdd"),
					contract.contract.next_valid_from ? DateTime.fromFormat(contract.contract.next_valid_from.toString(), "yyyyMMdd").minus({ days: 1 }) : null,
				), }))
			);
		}
		this.validity = validity;
	}
	public static from(state: TimeManagementRecord): TimeManagement {
		return new TimeManagement(state, state.id);
	}

	private constructor(timeManagement: TimeManagementRecord, public readonly dscaid: number) {
		if (!timeManagement || Object.keys(timeManagement).length === 0) {
			return;
		}
		this.years.set(timeManagement.year, timeManagement);
		this.runIndex();
	}

	public addRecord(record: TimeManagementRecord): void {
		if(this.dscaid !== record.id) {
			throw Error("Id does not match!");
		}
		this.years.set(record.year, record);
		this.runIndex();
	}

	private isTerminated(point: DateTime): boolean {
		const contract = this.getValidContract(point);
		if (!contract) return true;
		if (contract.contract.termination_date == null) return false;
		return point <= DateTime.fromFormat(contract.contract.termination_date.toString(), "yyyyMMdd");
	}

	private getValidContract(point: DateTime) {
		return this.validity?.find(e => e.validity.includes(point));
	}
	protected getContractData(point: DateTime) {
		const contract = this.getValidContract(point);
		return contract.tdt.tdt_year;
	}

	public getDayHoursNet(point: DateTime): number {
		if (this.isTerminated(point)) return 0;
		const tdt = this.getContractData(point);
		return tdt?.tdt_d_n;
	}

	public getWeekHoursNet(point: DateTime): number {
		const tdt = this.getContractData(point);
		return tdt?.tdt_w_n;
	}

	public getYearHoursGross(point: DateTime): number {
		return this.years.get(point.year)?.data.tdt_year.tdt_y_g;
	}

	public getYearHoursNet(point: DateTime): number {
		const contractInfo = this.getValidContract(point);
		return contractInfo?.tdt.tdt_y_n;
	}

	/**
	 * used in ARA-Project
	 * @param point 
	 * @returns 
	 */
	public getYearHoursNetForYear(point: DateTime): number {
		return this.years.get(point.year)?.data.tdt_year.tdt_y_n;
	}

	public getTdTMonth(point: DateTime): number {
		return this.years.get(point.year)?.data.tdt_year.tdt_months?.[point.month]?.tdt_m_g;
	}

	public getTargetDutyTimeDayNet(point: DateTime): number {
		const contractInfo = this.getValidContract(point);
		return contractInfo?.tdt?.tdt_year.tdt_d_n;
	}

	public getHolidayCount(point: DateTime): number {
		const contractInfo = this.getValidContract(point);
		return contractInfo?.tdt?.vacationPerYearInDays;
	}

	public getVacPerYearInDays(point: DateTime): number {
		const contractInfo = this.getValidContract(point);
		return contractInfo?.tdt?.vacationPerYearInDays;
	}

	public getWorkdaysPerWeek(point: DateTime): number {
		const contractInfo = this.getValidContract(point);
		return contractInfo?.tdt?.workdaysPerWeek;
	}

}
