import { apiManager, FpApi } from "@tcs-rliess/fp-core";
import { action } from "mobx";

import { BaseStoreSingle } from "../BaseStoreSingle";

const bookingItemService = apiManager.getService(FpApi.Booking.BookingItemService);

export class BookingItemStore extends BaseStoreSingle<FpApi.Booking.BookingItem, string> {
	protected itemId(item: FpApi.Booking.BookingItem): string {
		return item.id;
	}

	protected async fetchIdList(idList: string[]): Promise<FpApi.Booking.BookingItem[]> {
		const loadedBookingItems = await apiManager
			.getService(FpApi.Booking.BookingItemService)
			.get(this.app.ctx, { id: idList });

		return loadedBookingItems;
	}

	@action public async get(params: FpApi.Booking.BookingItemServiceGetParams): Promise<FpApi.Booking.BookingItem[]> {
		const bookingItems = await bookingItemService.get(this.app.ctx, params);

		for (const bookingItem of bookingItems) {
			this.update(bookingItem);
		}

		return bookingItems;
	}

	@action public async delete(id: string): Promise<void> {
		await bookingItemService.delete(this.app.ctx, { data: { id: id }});
		this.remove(id);
	}

	@action public async cancel(id: string[]): Promise<FpApi.Booking.BookingItem[]> {
		const items = await bookingItemService.cancel(this.app.ctx, {
			id: id
		});
		for (const item of items) {
			this.update(item);
		}
		return items;
	}

	@action public async put(data: FpApi.Booking.BookingItemServicePutParams["data"], /* event?: FpApi.Booking.BookingItemServicePutParams["event"] */): Promise<FpApi.Booking.BookingItem[]> {
		const items = await bookingItemService.put(this.app.ctx, {
			data: data,
			// ...(event && { event: event }),
		});
		for (const item of items) {
			this.update(item);
		}
		return items;
	}

	@action public async deliverItem(data: FpApi.Booking.BookingItemDeliverParams): Promise<FpApi.Booking.BookingItem> {
		const item = await bookingItemService.deliverItem(this.app.ctx, { data, });
		this.update(item);

		return item;
	}

	@action public async post(data: FpApi.Booking.BookingItemServicePostParams["data"], /* event?: FpApi.Booking.BookingItemServicePostParams["event"], */ processLink?: FpApi.Booking.BookingItemServicePostParams["processLink"]): Promise<FpApi.Booking.BookingItem[]> {
		const items = await bookingItemService.post(this.app.ctx, {
			data: data,
			// ...(event && { event: event }),
			...(processLink && { processLink: processLink }),
		});

		for (const item of items) this.update(item);
		return items;
	}
}
