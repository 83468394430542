import React from "react";
import styled, { useTheme } from "styled-components";
import { useThemeColor } from "../../Css";
export const Text = React.forwardRef(function Text({ color, background, muted, size, italic, bold, underline, variant = "span", light, ...props }, ref) {
    const theme = useTheme();
    let finalBackground;
    let finalColor;
    const backgroundColor = useThemeColor(background);
    if (backgroundColor) {
        finalBackground = backgroundColor[500].main;
        finalColor = backgroundColor[500].font;
    }
    const colorInfo = useThemeColor(color);
    if (colorInfo) {
        finalColor = colorInfo[500].main;
    }
    if (muted) {
        finalColor = theme.fontMuted;
    }
    const Component = getComponent(variant);
    return React.createElement(Component, { ...props, "$size": size ?? "md", "$italic": italic, "$weight": bold ? "bold" : light ? "300" : undefined, "$underline": underline, ref: ref, style: {
            color: finalColor,
            background: finalBackground,
            ...(props.style ?? {})
        } }, props.children);
});
function ComponentFactory(variant) {
    return styled[variant] `
		${p => {
        switch (p.$size) {
            case "xs": return "--font-size: .75em;";
            case "sm": return "--font-size: .8em;";
            case "md": return "--font-size: 1em;";
            case "lg": return "--font-size: 1.25em;";
            default: return "--font-size: 1em;";
        }
    }}

		font-size: var(--font-size);
		font-style: ${p => p.$italic ? "italic" : "normal"};
		font-weight: ${p => p.$weight ? p.$weight : "normal"};
		text-decoration: ${p => p.$underline ? "underline dotted" : "none"};
	`;
}
const components = {
    span: ComponentFactory("span"),
    p: ComponentFactory("p"),
    div: ComponentFactory("div"),
};
function getComponent(variant) {
    if (components[variant] == null)
        components[variant] = ComponentFactory(variant);
    return components[variant];
}
