var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { omit } from "lodash";
import { action, observable, transaction } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import ReactDOM from "react-dom";
import styled, { css } from "styled-components";
import { useOutsideAlerter } from "../../lib";
import { Button } from "../Button";
import { Flex, FlexItem } from "../Flex";
import { Text } from "../Text";
function useHover(onHover, ref, timeout) {
    const [to, setTo] = React.useState();
    const [hover, setHover] = React.useState();
    const [close, setClose] = React.useState();
    React.useEffect(() => {
        const hover = () => {
            clearTimeout(to);
            const nto = setTimeout(onHover, timeout ?? 500);
            setTo(nto);
        };
        setHover(hover);
        ref.current?.addEventListener("mouseenter", hover);
    }, [ref]);
    React.useEffect(() => {
        const leave = () => {
            setTo((to) => {
                clearTimeout(to);
                return null;
            });
        };
        setClose(leave);
        ref.current?.addEventListener("mouseleave", leave);
    }, [ref]);
    React.useEffect(() => {
        const current = ref.current;
        return () => {
            current?.removeEventListener("mouseenter", hover);
            current?.removeEventListener("mouseleave", close);
        };
    }, []);
}
class MenuState {
    constructor(open) {
        this.open = open;
    }
    setOpen(open) {
        this.open = open;
    }
}
__decorate([
    observable
], MenuState.prototype, "open", void 0);
__decorate([
    action
], MenuState.prototype, "setOpen", null);
export const MenuCtx = React.createContext(null);
export const ContainerCtx = React.createContext(null);
const Div = styled.div ``;
export const DropdownDefaultValuesCtx = React.createContext({
    openLeft: false,
    openUp: false,
});
const collectionContext = React.createContext(null);
function useDropdownMenu({ m, ref }) {
    //collection of menues
    const stateCollection = React.useContext(collectionContext) || m;
    const idRef = React.useRef();
    const [childState] = React.useState(() => new MenuState());
    childState.ref = ref;
    if (!idRef.current) {
        idRef.current = Math.random().toString(36).substring(7);
    }
    React.useEffect(() => {
        stateCollection.set(idRef.current, childState);
        return () => {
            stateCollection.delete(idRef.current);
        };
    }, []);
    return { state: childState, id: idRef.current };
}
const DropdownContainer = observer(React.forwardRef(function DropdownContainer(props, innerRef) {
    const [open, setOpen] = React.useState(false);
    const ref = React.useRef();
    const containerRef = React.useRef();
    const [stateCollection] = React.useState(() => new Map());
    useOutsideAlerter(ref, (event) => {
        for (const [, value] of stateCollection) {
            if (value.ref.current.contains(event.target)) {
                return;
            }
        }
        setOpen(false);
        transaction(() => stateCollection.forEach((v) => v.setOpen(null)));
        props.onClose?.(event);
    });
    const distanceToTop = (() => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            return rect.top + window.scrollY;
        }
        return 0;
    })();
    const { state: childState } = useDropdownMenu({ m: stateCollection, ref });
    React.useImperativeHandle(innerRef, () => ({
        ref,
        container: { closeRoot: () => {
                stateCollection.forEach((v) => v.setOpen(null));
                setOpen(false);
            } },
        childState: childState,
        props: { openLeft: props.openLeft, openUp: props.openUp },
        setOpen
    }));
    // React.useEffect(() => {
    // 	setChildState(new MenuState());
    // }, [ open ]);
    const onClickhandler = React.useMemo(() => {
        const arrayOfChildren = React.Children.toArray(props.children).filter(Boolean);
        if (arrayOfChildren.length === 1 && !arrayOfChildren[0].props.children?.length && arrayOfChildren[0].props.onClick) {
            if (arrayOfChildren[0].props.disabled)
                return;
            return arrayOfChildren[0].props.onClick;
        }
    }, [props.children]);
    const isDisabled = React.useMemo(() => {
        const arrayOfChildren = React.Children.toArray(props.children).filter(Boolean);
        if (arrayOfChildren.length === 1 && !arrayOfChildren[0].props.children?.length && arrayOfChildren[0].props.onClick) {
            return arrayOfChildren[0].props.disabled;
        }
        return false;
    }, [props.children]);
    const Container = React.useMemo(() => props.component || Div, []);
    return React.createElement(collectionContext.Provider, { value: stateCollection },
        React.createElement(DropdownDefaultValuesCtx.Provider, { value: { openLeft: props.openLeft, openUp: props.openUp } },
            React.createElement("div", { ref: containerRef, style: { position: "relative", ...(props.style ?? {}) } },
                React.createElement(Container, { ...props.componentProps, disabled: props.componentProps?.disabled || isDisabled, onClick: (e) => {
                        if (isDisabled)
                            return;
                        e.preventDefault();
                        e.stopPropagation();
                        if (onClickhandler && props.enableSingleClick) {
                            onClickhandler(e);
                        }
                        else {
                            setOpen(!open);
                            props.onClick?.(e);
                        }
                    } },
                    React.createElement(ContainerCtx.Provider, { value: { closeRoot: () => {
                                setOpen(false);
                                stateCollection.forEach((v) => v.setOpen(null));
                                props.onClose?.(null);
                            } } },
                        props.title,
                        React.createElement(MenuCtx.Provider, { value: childState },
                            React.createElement(Dropdown.Menu, { ...(props.menuProps ?? {}), style: {
                                    position: "absolute",
                                    maxHeight: `calc(100vh - ${containerRef.current?.offsetHeight}px - ${distanceToTop}px)`,
                                    [props.openUp ? "bottom" : "top"]: containerRef.current?.offsetHeight,
                                    overflowY: "auto",
                                    display: open ? undefined : "none",
                                    [props.openLeft ? "right" : "left"]: 0,
                                    ...(props.menuProps?.style ?? {})
                                }, onClick: (e) => e.stopPropagation(), ref: ref }, props.children)))))));
}));
DropdownContainer.defaultProps = {
    component: Button,
};
const MenuItem = observer(function MenuItem(props) {
    const ref = React.useRef();
    const parentState = React.useContext(MenuCtx);
    const [tabKey] = React.useState(props.tabKey || Symbol());
    useHover(() => {
        parentState.setOpen(tabKey);
    }, ref, 200);
    const y = (() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            return rect.top + window.scrollY;
        }
        return 0;
    })();
    const x = (() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            return rect.left + window.scrollX;
        }
        return 0;
    })();
    const offsetX = (() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            return rect.right - rect.left;
        }
        return 0;
    })();
    const ctx = React.useContext(ContainerCtx);
    const { state: childState } = useDropdownMenu({ ref });
    if (props.children) {
        return React.createElement("div", { ref: ref, style: { position: "relative" }, onClick: (e) => {
                e.stopPropagation();
            } },
            React.createElement(MenuItemStyled, { ...omit(props, "title", "ref"), style: { padding: "6px 16px", ...(props.style ?? {}), cursor: props.disabled ? "not-allowed" : "pointer" }, "data-testid": "dropdownoption" },
                React.createElement(Flex, { alignItems: "center", onClick: (e) => {
                        if (props.disabled) {
                            e.stopPropagation();
                            e.preventDefault();
                            return;
                        }
                    } },
                    React.createElement(FlexItem, { grow: true },
                        React.createElement(Text, { muted: props.disabled }, props.title)),
                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faChevronDown }))),
            (parentState.open === tabKey && !props.disabled) && React.createElement(MenuCtx.Provider, { value: childState },
                React.createElement(MenuItemPortal, { ...props, x: x, y: y, offset: offsetX, tabKey: tabKey, open: parentState.open === tabKey })));
    }
    return React.createElement(MenuItemStyled, { ...omit(props, "title", "ref", "onClick"), style: { padding: "6px 16px", ...(props.style ?? {}), cursor: props.disabled ? "not-allowed" : "pointer" }, "data-testid": "dropdownoption", ref: ref, onClick: (e) => {
            if (props.disabled) {
                e?.stopPropagation?.();
                e?.preventDefault?.();
            }
            else {
                props.onClick?.(e);
            }
            ctx.closeRoot();
        } },
        React.createElement(Text, { muted: props.disabled }, props.title));
});
const MenuItemPortal = observer(function MenuItemPortal(props) {
    const ref = React.useRef();
    const defaults = React.useContext(DropdownDefaultValuesCtx);
    const openLeft = props.openLeft ?? defaults.openLeft;
    const openUp = props.openUp ?? defaults.openUp;
    const [offsetY, setOffsetY] = React.useState(0);
    React.useEffect(() => {
        if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            setOffsetY(rect.height);
        }
    }, [props.open]);
    const { x, y } = props;
    if (!props.open)
        return null;
    return ReactDOM.createPortal(React.createElement(Dropdown.Menu, { ref: ref, style: {
            position: "absolute",
            left: x + props.offset * (openLeft ? -1 : 1),
            top: y + (openUp ? -offsetY : 0),
        }, onClick: (e) => {
            e.stopPropagation();
        } }, props.children), document.body);
});
const MenuItemStyled = styled.div `
	cursor: pointer;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	${p => p.active ? css `
		background: ${p => p.theme.color.primary[500].main};
		color: ${p => p.theme.color.primary[500].font};
		&:hover {
			background: ${p => p.theme.color.primary[600].main};
		}
	` : css `
		&:hover {
			background: ${p => p.theme.dropdown.hover};
		}
	`}

`;
MenuItemStyled.displayName = "MenuItem";
const MenuSeparatorStyled = styled.div `
	border-bottom: 1px solid ${p => p.theme.dropdown.borderColor};
	padding-left: 0.5rem;
	background-color: #f3f3f3;
	cursor: default;
`;
MenuSeparatorStyled.displayName = "MenuSeparator";
const MenuPanelStyled = styled.div `
	border-bottom: 1px solid ${p => p.theme.dropdown.borderColor};
	padding: 0;
	background-color: ${p => p.theme.background};
	cursor: default;
`;
MenuPanelStyled.displayName = "MenuPanel";
const Menu = React.forwardRef(function Menu(props, ref) {
    return React.createElement(MenuStyled, { ...props, ref: ref });
});
export const MenuStyled = styled.div `
	border: 1px solid ${p => p.theme.dropdown.borderColor};
	background: ${p => p.theme.dropdown.background};
	min-width: 200px;
	max-width: 400px;
	/* padding-top: 4px;
	padding-bottom: 4px; */
	text-align: left;
	z-index: 200;
	color: black;
`;
MenuStyled.displayName = "Menu";
const MenuOpGroup = styled.div `
	font-size: 11px;
	color: #aaa;
	margin-left: 16px;
`;
const Title = function Title(props) {
    return React.createElement(MenuOpGroup, { ...omit(props, "children") },
        React.createElement("span", { style: { background: "white" } }, props.children));
};
export const Dropdown = Object.assign(DropdownContainer, {
    Item: MenuItem,
    Separator: MenuSeparatorStyled,
    Menu: Menu,
    Title: Title,
    Panel: MenuPanelStyled,
});
