import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useImperativeHandle } from "react";
import { useController } from "react-hook-form";
import { useFieldHook, useTimezone } from "../../../index";
import { Input } from "../../Input/Input";
const InternalTimeInput = React.forwardRef(function TimeInput2(props, ref) {
    const tz = useTimezone(props.tz);
    /** `props.value` turned into a DateTime */
    const value = React.useMemo(() => {
        // guard in case falsy value is provided
        if (!props.value)
            return null;
        let value;
        if (props.value instanceof DateTime) {
            value = props.value;
        }
        else if (typeof props.value === "string") {
            value = DateTime.fromISO(props.value, { zone: tz });
        }
        value = value.setZone(tz ?? "default");
        return value;
    }, [props.value, tz]);
    /** `string` value of the internal <input> field */
    const [inputString, setInputString] = React.useState(value ? value.toFormat("HH:mm") : "");
    // update input if the props "value" changed
    React.useEffect(() => {
        if (document.activeElement === inputRef.current) {
            // don't update if user has focus
            return;
        }
        if (value?.isValid) {
            setInputString(value ? value.toFormat("HH:mm") : "");
        }
        else {
            setInputString("");
        }
    }, [value?.toISO()]);
    // Below stuff is used to keep the cursor position if you use the arrow up and down keys to manipulate the value in the input. Normally it would jump to the
    // start/end of the input.
    const inputRef = React.useRef();
    useImperativeHandle(ref, () => inputRef.current);
    const [cursorPos, setCursorPos] = React.useState(0);
    const [keyDown, setKeyDown] = React.useState(false);
    React.useEffect(() => {
        if (keyDown && inputRef.current) {
            inputRef.current.selectionStart = cursorPos;
            inputRef.current.selectionEnd = cursorPos;
            setKeyDown(false);
        }
    });
    function triggerChange(inputString) {
        let parsed = parse(inputString);
        if (props.clearable && parsed.isValid === false) {
            props.onChange?.(null);
            return null;
        }
        // ignore invalid inputs
        if (parsed?.isValid === false)
            return value;
        // validate min
        if (props.min) {
            const min = DateTime
                .fromISO(props.min, { zone: tz });
            // .set({ day: parsed.day, month: parsed.month, year: parsed.year });
            if (parsed < min)
                parsed = min;
        }
        // validate max
        if (props.max) {
            const max = DateTime
                .fromISO(props.max, { zone: tz });
            // .set({ day: parsed.day, month: parsed.month, year: parsed.year });
            if (parsed > max)
                parsed = max;
        }
        // don't trigger `onChange` if value is the same, would cause
        // - unnecessary rendering
        // - infinite rerender loop
        parsed = parsed.startOf("minute");
        if (value != null && parsed.hasSame(value, "millisecond"))
            return parsed;
        // report change
        props.onChange?.(parsed);
        return parsed;
    }
    function parse(input) {
        let out = DateTime.invalid("default");
        input = input.replace(/[^0-9]/g, ""); // remove all useless symbols that we don't need while parsing
        switch (input.length) {
            case 1:
            case 2:
                out = DateTime.fromFormat(input, "H", { zone: tz });
                break;
            case 3:
            case 4:
                out = DateTime.fromFormat(input, "Hmm", { zone: tz });
                break;
            case 5:
            case 6:
                out = DateTime.fromFormat(input, "Hmmss", { zone: tz });
                break;
        }
        if (out.isValid && value != null) {
            // try to keep the date if we have an input value
            out = out.set({
                day: value.day,
                month: value.month,
                year: value.year,
            });
        }
        return out;
    }
    function onKeyDown(e) {
        if (e.key === "Enter") {
            inputRef.current.blur();
            return;
        }
        if (e.key !== "ArrowDown" && e.key !== "ArrowUp")
            return;
        const selectionStart = e.currentTarget.selectionStart;
        // set this so we can restore the selection on the following "componentDidUpdate"
        setKeyDown(true);
        setCursorPos(selectionStart);
        e.preventDefault();
        let time = parse(inputString);
        const prevTime = time;
        let step = 1;
        if (e.key === "ArrowDown")
            step = -1;
        if (e.shiftKey)
            step *= 10;
        if (inputString.includes(":")) {
            switch (selectionStart) {
                case 0:
                case 1:
                case 2:
                    time = time.plus({ hours: step });
                    break;
                case 3:
                case 4:
                case 5:
                    time = time.plus({ minutes: step });
                    break;
                case 6:
                case 7:
                case 8:
                    time = time.plus({ seconds: step });
                    break;
            }
        }
        else {
            switch (selectionStart) {
                case 0:
                    time = time.plus({ hours: step });
                    break;
                case 1:
                    time = time.plus({ hours: step });
                    break;
                case 2:
                    time = time.plus({ minutes: step });
                    break;
                case 3:
                    time = time.plus({ minutes: step });
                    break;
                case 4:
                    time = time.plus({ seconds: step });
                    break;
                case 5:
                    time = time.plus({ seconds: step });
                    break;
            }
        }
        // abort if we would change the day
        if (!prevTime.hasSame(time, "day")) {
            return;
        }
        const format = time.toFormat("HH:mm");
        // time
        setInputString(format);
        // why are we parsing the time here, we just formatted it, why wouldn't it not valid
        // also we're updating the displayed value ("inputString") but not trigger a change
        if (parse(format).isValid) {
            triggerChange(format);
        }
    }
    const styles = { textAlign: "right" };
    Object.assign(styles, props.style);
    return (React.createElement(props.as, { "data-testid": props["data-testid"], ref: inputRef, inputMode: "numeric", value: inputString, disabled: props.disabled, placeholder: props.placeholder ?? "HH:mm", tabIndex: props.tabIndex, onKeyDown: onKeyDown, onChange: (ev) => {
            setInputString(ev.target.value);
        }, onFocus: () => setTimeout(() => inputRef.current?.select(), 1), 
        // why are we using "onBlurCapture" instead of normal "onBlur"?
        onBlur: () => {
            const newVal = triggerChange(inputString);
            // reset to proper value we are supposed to display (`props.value`) after user blurs field
            setInputString(newVal ? newVal.toFormat("HH:mm") : "");
        }, style: styles, bootstrap: props.bootstrap, className: props.bootstrap ? classNames("form-control", props.className) : props.className }));
});
const InternalTimeInputHook = (props) => {
    const { name } = useFieldHook() ?? {};
    const finalName = props.name || name;
    const controller = useController({ name: finalName });
    return React.createElement(TimeInput, { ...controller.field, onChange: e => {
            controller.field.onChange(e?.toISO() ?? null);
            props.onChange?.(e);
        }, ...props, as: Input });
};
InternalTimeInput.defaultProps = {
    as: Input,
};
export const TimeInput = Object.assign(InternalTimeInput, {
    Hook: InternalTimeInputHook,
});
