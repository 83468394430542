import { castArray } from "lodash";
import React from "react";
import styled, { css } from "styled-components";
import { useThemeColor } from "../../Css";
export const FPToggleButton = function ToggleButton(props) {
    const [firstColor, secondColor] = castArray(props.variant);
    const onColor = useThemeColor(firstColor, "#fff");
    const offColor = useThemeColor(secondColor ?? "#e0e0e0", "#fff");
    const onRef = React.useRef(null);
    const offRef = React.useRef(null);
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    React.useLayoutEffect(() => {
        setTimeout(() => {
            if (props.width === "auto") {
                const nextWidth = (onRef.current?.clientWidth ?? 0) + (offRef.current?.clientWidth ?? 0) + 4;
                if (width !== nextWidth) {
                    setWidth(nextWidth);
                }
            }
            if (props.height === "auto") {
                const nextHeight = (onRef.current?.clientHeight ?? 0) + (offRef.current?.clientHeight ?? 0) + 4;
                if (height !== nextHeight) {
                    setHeight(nextHeight);
                }
            }
        });
    }, []);
    return (React.createElement(StyledToggleButton, { "$onColor": onColor[500].main, "$off": offColor[500].main, "$value": props.value, "$width": props.width === "auto" ? `${width}px` : typeof props.width === "number" ? `${props.width}px` : props.width, "$knobWidth": props.knobWidth ? typeof props.knobWidth === "number" ? `${props.knobWidth}px` : props.knobWidth : "7px", "$height": props.height === "auto" ? `${height}px` : typeof props.height === "number" ? `${props.height}px` : props.height },
        React.createElement(SliderContainer, null,
            React.createElement(OnSlider, { "$on": onColor[500].main },
                React.createElement(On, { style: { color: onColor[500].font }, ref: onRef }, props.on)),
            React.createElement(OffSlider, { "$off": offColor[500].main },
                React.createElement(Off, { style: { color: offColor[500].font }, ref: offRef }, props.off))),
        React.createElement("input", { disabled: props.disabled, "aria-disabled": props.disabled, checked: props.value, type: "checkbox", onChange: () => props.onChange(!props.value), "data-testid": props["data-testid"] })));
};
const SliderContainer = styled.div `
	width: 100%;
	overflow: hidden;
	position: absolute;
	height: 100%;
`;
const On = styled.div `
	position: absolute;
	white-space: nowrap;
	top: 0;
	right: calc(var(--knob-width) + 10px);
`;
const Off = styled.div `
	white-space: nowrap;
	position: absolute;
	top: 0;
	left: calc(var(--knob-width) + 10px);
`;
const OnSlider = styled.div `
	position: absolute;
	top: 0;
	right: 100%;
	width: 100%;
	height: 100%;
	background-color: ${p => p.$on};
	transition: all var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
`;
const OffSlider = styled.div `
	position: absolute;
	top: 0;
	left: 100%;
	width: 100%;
	height: 100%;
	background-color: ${p => p.$off};
	transition: all var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
	// line-height: var(--component-height);
`;
FPToggleButton.defaultProps = {
    variant: ["success", "danger"],
    width: "auto",
    height: "auto",
};
export const StyledToggleButton = styled.div `
	--animation-duration: .4s;
	position: relative;
	--component-width: ${p => p.$width ?? "30px"};
	--component-height: ${p => p.$height ?? "30px"};
	--knob-width: ${p => p.$knobWidth ?? "7px"};
	--knob-height: calc(var(--component-height) - 4px);
	--knob-position: 2.5px;
	line-height: ${p => {
    return p.$height === "0px" ? "unset" : p.$height;
}};

	width: var(--component-width);
	height: var(--component-height);
	position: relative;
	cursor: pointer;
	input[type=checkbox] {
		width: var(--component-width);
		height: var(--component-height);
		cursor: pointer;
		appearance: none;
		position: relative;
		outline: 0;
		// transition: all var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
		margin-top: 0;
		transition: background-color var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
	}

	input[type=checkbox]:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}

	// knob
	input[type=checkbox]:after{
		position: absolute;
		content: "";
		top: var(--knob-position);
		left: var(--knob-position);
		width: var(--knob-width);
		height: var(--knob-height);
		background: #fff;
		z-index: 2;
		transition: left var(--animation-duration) cubic-bezier(.42, 0, 0.8, 0.35);
	}

	input[type=checkbox]:checked:after{
		left: calc(100% - var(--knob-width) - 3px);
	}

	${OnSlider} {
		right: 100%;
	}
	${OffSlider} {
		left: 0;
	}

	${p => p.$value ? css `
		${OnSlider} {
			right: 0;
		}
		${OffSlider} {
			left: 100%;
		}
	` : ""}

	/**
	* removed until firefox supports it
	*/
	/* &:has(input[type=checkbox]:checked) {
		${OnSlider} {
			right: 0;
		}
		${OffSlider} {
			left: 100%;
		}
	} */
`;
