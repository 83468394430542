import { apiManager, FpApi, TimeSpan } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { BaseStoreRange } from "../../BaseStoreRange";

export class ResourceCheckInStore extends BaseStoreRange<FpApi.Resource.Duty.ScheduleCheckIn, number> {
	protected filterOptions = {};

	protected itemId(item: FpApi.Resource.Duty.ScheduleCheckIn): number {
		return item.id;
	}
	protected itemDateModified(item: FpApi.Resource.Duty.ScheduleCheckIn): DateTime {
		return DateTime.fromISO(item.rlog.m);
	}

	protected itemRange(item: FpApi.Resource.Duty.ScheduleCheckIn): TimeSpan {
		return new TimeSpan(
			DateTime.fromISO(item.checkIn),
			item.checkOut ? DateTime.fromISO(item.checkOut) : DateTime.now().set({ year: 2500 }),
		);
	}

	protected async fetchIdList(idList: number[]): Promise<FpApi.Resource.Duty.ScheduleCheckIn[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.ScheduleCheckInService)
			.getIdList(this.app.ctx, { idList });
	}

	protected async fetchRange(from: DateTime, to: DateTime): Promise<FpApi.Resource.Duty.ScheduleCheckIn[]> {
		return await apiManager
			.getService(FpApi.Resource.Duty.ScheduleCheckInService)
			.get(this.app.ctx, {
				dateFrom: from.toISO(),
				dateTo: to.toISO(),
			});
	}
}
