import { ClientCategoryUtil, FpApi, FpDirClientState, RecencyType, sleep } from "@tcs-rliess/fp-core";
import { ThemeVariant } from "@tcs-rliess/fp-web-ui";
import { castArray, chain, flatten } from "lodash-es";
import { DateTime } from "luxon";

import { FleetplanApp } from "../../../FleetplanApp";

export enum RecencyColumn {
	None = 0,
	DayLanding = 1,
	NightLanding = 2,
	DayHofo = 4,
	NightHofo = 8,
	HHODay = 16,
	HHONight = 32,
	IFR = 64,
	NVIS = 128,
}

const visibility = {
	[RecencyColumn.DayLanding]: [
		"landings_day",
		"takeoffs_day",
		"landings_night",
		"takeoffs_night"
	],
	[RecencyColumn.NightLanding]: [
		"landings_night",
		"takeoffs_night"
	],
	[RecencyColumn.DayHofo]: [
		"hofo_takeoffs_day",
		"hofo_landings_day",
		"hofo_takeoffs_night",
		"hofo_landings_night",
		"takeoffs_shipboard",
		"takeoffs_platform"
	],
	[RecencyColumn.NightHofo]: [
		"hofo_takeoffs_night",
		"hofo_landings_night"
	],
	[RecencyColumn.HHODay]: [
		"winchcycles_day",
		"winchcycles_night",
	],
	[RecencyColumn.HHONight]: [
		"winchcycles_night",
	],
	[RecencyColumn.IFR]: [
		"ifr_procedure_checkflight",
		"ifr_procedure_approach",
	],
	[RecencyColumn.NVIS]: [
		/** Type of Flight: NVIS */
		"tof_nvg_bit",
		/** Type of Flight: NVIS Check Flight */
		"tof_nvg_cf_bit",
	],
};



export class RecencyUtil {
	public static RECENCY_DAYS = 90;
	constructor(private app: FleetplanApp) {

	}

	public async getUsersRecency(dscaid: number | number[]) {
		const states = await this.getContactState(dscaid);
		return new Map(Array.from(states.values()).map(state => [ state.id, {
			recency: state.recency,
			recency_updated: state.recency_updated,
		}]));
	}
	private async getContactState(dscaid: number | number[]) {
		const ids = castArray(dscaid);
		const states = flatten(await Promise.all(chain(ids)
			.chunk(50)
			.map(async id => {
				return await this.app.store.contactState.getIdList(id);
			})
			.value()));
		return new Map(states.map(state => [ state.id, state ]));
	}

	// TODO: Implement this method correctly. There is no way now to find the logtype for each user
	public async getLogTypes(dscaid: number | number[]) {
		const types = this.app.store.resource.userLogType.getType(FpApi.Resource.UserLogTypeResourceType.DSCAID).map(e => e.idName);
		await sleep(0);
		return new Map(castArray(dscaid).map(id => [ id, types ]));
	}

	public async loadVisibility(dscaid: number | number[]) {
		const logTypes = await this.getLogTypes(dscaid);
		return new Map(castArray(dscaid).map(e => ([ e, this.buildVisibility(logTypes.get(e)) ])));
	}

	private buildVisibility(logTypes: string[]): number {
		let visibilityMask = 0;
		for (const [ key, value ] of Object.entries(visibility)) {
			if(value.find(e => logTypes.includes(e))) {
				visibilityMask |= parseInt(key);
			}
		}
		return visibilityMask;
	}

	public isVisible(mask: number, column: RecencyColumn | RecencyColumn[] | number | number[]): boolean {
		if(Array.isArray(column)) {
			return column.some(e => (mask & e) === e);
		}
		return (mask & column) === column;
	}

	public async getRecency(dscaid: number | number[]) {
		const recency = await this.getContactState(dscaid);
		const logTypes = await this.loadVisibility(dscaid);
		return new Map(castArray(dscaid).map(id => {
			const visibility = logTypes.get(id);
			const rec = recency.get(id);
			return [ id, {
				recency: rec,
				visible: visibility,
			}];
		}));
	}

	public getRecencyFromRecencyType(recency: RecencyType[], contactState: FpDirClientState, licenseEndorsement?: string, againstDate?: DateTime): Partial<Record<RecencyType, number>>{
		const obj: Partial<Record<RecencyType, number>> = {};
		const { recency: recObj } = contactState;
		for(const type of recency) {
			switch(type) {
				// ifr calculation: take the worst from ifr_procedure_approach and ifr_flight
				// will be overruled by checlflight
				case RecencyType.IFR: {
					obj[RecencyType.IFR] = this.counterByWorst([
						recObj.global.ifr_procedure_approach,
						recObj.global.ifr_flight,
					], recObj.global.ifr_procedure_checkflight, againstDate);
					break;
				}
				case RecencyType.NVIS: {
					obj[RecencyType.NVIS] = this.counterByWorst([
						recObj.global.nvis_checkflight,
						recObj.global.nvis_flight,
					], undefined, againstDate);
					break;
				}
				case RecencyType.HHODay: {
					obj[RecencyType.HHODay] = this.counterByBest([
						recObj.global.hho_day,
					], undefined, againstDate);
					break;
				}
				case RecencyType.HHONight: {
					obj[RecencyType.HHONight] = this.counterByBest([
						recObj.global.hho_night,
					], undefined, againstDate);
					break;
				}
				case RecencyType.DayLanding: {
					const ldDay =  this.calculateValidDays(recObj.license_endorsement[licenseEndorsement]?.landings_day, againstDate);
					const ldNight = this.calculateValidDays(recObj.license_endorsement[licenseEndorsement]?.landings_night, againstDate);
					obj[RecencyType.DayLanding] = Math.max(ldDay, ldNight);
					break;
				}
				case RecencyType.NightLanding: {
					const ldNight = this.calculateValidDays(recObj.license_endorsement[licenseEndorsement]?.landings_night, againstDate);
					const takeoffNight = this.calculateValidDays(recObj.license_endorsement[licenseEndorsement]?.takeoffs_night, againstDate);
					obj[RecencyType.NightLanding] = ldNight <= takeoffNight ? ldNight : takeoffNight;
					break;
				}
				case RecencyType.DayHofo: {
					obj[RecencyType.DayHofo] = this.calculateValidDays(recObj.license_endorsement[licenseEndorsement]?.hofo_day, againstDate);
					break;
				}
				case RecencyType.NightHofo: {
					obj[RecencyType.NightHofo] = this.calculateValidDays(recObj.license_endorsement[licenseEndorsement]?.hofo_night, againstDate);
					break;
				}
			}
		}
		return obj;
	}

	public getRecencyCalculation(recency: RecencyType[] = [], contactState: FpDirClientState, licenseEndorsement?: string, againstDate?: DateTime): {
		variant: ThemeVariant,
		status: {
			type: RecencyType;
			variant: ThemeVariant;
			days: number;
		}[],
	}{
		const rec = this.getRecencyFromRecencyType(recency, contactState, licenseEndorsement, againstDate);
		const util = ClientCategoryUtil.byEnum(RecencyType);
		const variants: ReturnType<typeof this.getValidStatus>[] = [];
		const status: {
			type: RecencyType;
			variant: ThemeVariant;
			days: number;
		}[] = [];

		for(const type of recency) {
			if(licenseEndorsement && util.getOption(type).type.includes("licenseEndorsement") === false) continue;
			if(licenseEndorsement == null && util.getOption(type).type.includes("licenseEndorsement")) continue;
			const days = rec[type];
			const variant = this.getValidStatus(days);
			variants.push(variant);
			status.push({
				type,
				variant,
				days
			});
		}
		return {
			variant: this.getWorstStatus(variants),
			status,
		};
	}

	private getWorstStatus(type: ReturnType<typeof this.getValidStatus>[]) {
		if(type.includes("danger")) return "danger";
		if(type.includes("warning")) return "warning";
		return "success";
	}

	public getValidStatus(days: number) {
		if(days < 0) return "danger";
		if(days < 10) return "warning";
		return "success";
	}

	public calculateValidDays(entry: string, againstDate?: DateTime): number {
		const date = againstDate ?? DateTime.now();
		const daysValid = entry == null ? -1 : DateTime.fromISO(entry).startOf("day").plus({ days: RecencyUtil.RECENCY_DAYS }).diff(date, "days")?.days;
		return daysValid;
	}

	public getBestDate(entries: string[]) {
		return chain(entries)
			.filter(Boolean)
			.map(e => ({ days: this.calculateValidDays(e), entry: e }))
			.sortBy("days")
			.last()
			.value()?.entry;
	}

	public getBestDays(entries: string[]) {
		const days = chain(entries)
			.filter(Boolean)
			.map(e => ({ days: this.calculateValidDays(e), entry: e }))
			.sortBy("days")
			.last()
			.value()?.days;
		return days ? Math.ceil(days) : null;
	}

	public getWorstDate(entries: string[]) {
		return chain(entries)
			.map(e => e ? e : DateTime.fromObject({ year: 1970 }).toISO())
			.map(e => ({ days: this.calculateValidDays(e), entry: e }))
			.sortBy("days")
			.first()
			.value()?.entry;
	}

	public getWorstDays(entries: string[]) {
		const days = chain(entries)
			.map(e => e ? e : DateTime.fromObject({ year: 1970 }).toISO())
			.map(e => ({ days: this.calculateValidDays(e), entry: e }))
			.sortBy("days")
			.first()
			.value()?.days;
		return days ? Math.ceil(days) : null;
	}

	public counterByWorst(entries: string[], overrule?: string,  againstDate?: DateTime) {
		const entry = chain(entries)
			.map(e => e ? e : DateTime.fromObject({ year: 1970 }).toISO())
			.map(e => ({ days: this.calculateValidDays(e, againstDate), entry: e }))
			.sortBy("days")
			.first()
			.value()?.entry;
		if(overrule) {
			const overruleDays = this.calculateValidDays(overrule, againstDate);
			if(overruleDays > this.calculateValidDays(entry, againstDate)) {
				return overruleDays;
			}
		}
		return this.calculateValidDays(entry, againstDate);
	}

	public counterByBest(entries: string[], overrule?: string, againstDate?: DateTime) {
		const entry = chain(entries)
			.map(e => e ? e : DateTime.fromObject({ year: 1970 }).toISO())
			.map(e => ({ days: this.calculateValidDays(e, againstDate), entry: e }))
			.sortBy("days")
			.first()
			.value()?.entry;
		if(overrule) {
			const overruleDays = this.calculateValidDays(overrule, againstDate);
			if(overruleDays > this.calculateValidDays(entry, againstDate)) {
				return overruleDays;
			}
		}
		return this.calculateValidDays(entry, againstDate);
	}

}
