import { FpApi, FpId, Prettify } from "@tcs-rliess/fp-core";
import EventEmitter from "events";
import { castArray } from "lodash-es";

import { BalanceBucket, CheckInBucket, CustomBucket, PremiumBucket, ScheduleBucket } from "./BalanceBucket";
import { ScheduleBalanceResponse } from "./ScheduleBalanceResponse";
import { type ScheduleBalanceStore } from "./ScheduleBalanceStore";
import { BalanceKeys, BaseParams, CheckInQuery, CheckInQueryParams, CustomQuery, CustomQueryParams, PremiumQuery, PremiumQueryParams, QueryParams, ScheduleQuery, ScheduleQueryParams, UnFlattenArrayKeys } from "./types";

export class ScheduleBalanceContactBucket extends EventEmitter {

	private readonly bucketMap = {
		schedule: new Map<number, ScheduleBucket>(),
		checkIn: new Map<number, CheckInBucket>(),
		custom: new Map<number, CustomBucket>(),
		premium: new Map<number, PremiumBucket>(),
	};

	public getDelta(year: number): number {
		return this._owner.getDelta(year, this.dscaid);
	}

	public getEntitlement(year: number): number {
		return this._owner.getEntitlement(year, this.dscaid);
	}

	public get contactState() {
		return this._owner.getContactState(this.dscaid);
	}

	public get timeManagement() {
		return this._owner.getTimeManagement(this.dscaid);
	}

	private readonly bucketClassMap = {
		schedule: ScheduleBucket,
		checkIn: CheckInBucket,
		custom: CustomBucket,
		premium: PremiumBucket,
	};

	public get owner() {
		return this._owner;
	}

	public emit(eventName: "drop", arg: string): boolean
	public emit(eventName: string | symbol, ...args: any[]): boolean {
		if(eventName === "drop") {
			const first = `${this.dscaid}:${args[0]}`;
			this._owner.notify(first);
			return super.emit(eventName, first);
		}
		return super.emit(eventName, ...args);

	}
	constructor(private _dscaid: number, public readonly originalData: FpApi.Resource.Duty.ScheduleBalance[], protected _owner: ScheduleBalanceStore) {
		super();
		for(const data of originalData) {
			if(data.linkId === this.dscaid.toString()) {
				const schedule = new ScheduleBucket(this.dscaid, data.date, this, data);
				this.bucketMap.schedule.set(data.date, schedule);
				const checkIn = new CheckInBucket(this.dscaid, data.date, this, data);
				this.bucketMap.checkIn.set(data.date, checkIn);
				const custom = new CustomBucket(this.dscaid, data.date, this, data);
				this.bucketMap.custom.set(data.date, custom);
				const premium = new PremiumBucket(this.dscaid, data.date, this, data);
				this.bucketMap.premium.set(data.date, premium);
			}
		}
	}

	/**
	 * for now this is fine
	 */
	public queryMany = (<T extends BalanceKeys>(type: T, from: number, to: number, params: QueryParams[T][]): ScheduleBalanceResponse[] => {
		switch(type) {
			case "schedule": {
				const typedParams = params as ScheduleQueryParams[];
				return this.querySchedule({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e));
			}
			case "checkIn": {
				const typedParams = params as CheckInQueryParams[];
				return this.queryCheckIn({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e));
			}
			case "custom": {
				const typedParams = params as CustomQueryParams[];
				return this.queryCustom({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e));
			}
			case "premium": {
				const typedParams = params as PremiumQueryParams[];
				return this.queryPremium({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e));
			}
		}
	})
	public get dscaid() {
		return this._dscaid;
	}

	protected ensureBucket(date: number, type: keyof typeof this.bucketMap): void {
		const bucket = this.bucketMap[type];
		if(bucket.has(date)) return;
		const bucketInstance = new this.bucketClassMap[type](this.dscaid, date, this);
		bucket.set(date, bucketInstance as any);
	}

	public insertBalance(balance: FpApi.Resource.Duty.ScheduleBalance): void {
		this.superInsertBalance("schedule", balance);
		this.superInsertBalance("checkIn", balance);
		this.superInsertBalance("custom", balance);
		this.superInsertBalance("premium", balance);
	}

	protected superInsertBalance(type: keyof typeof this.bucketMap, balance: FpApi.Resource.Duty.ScheduleBalance): void {
		const bucket = this.bucketMap[type];
		const BucketClass = this.bucketClassMap[type];
		this.ensureBucket(balance.date, type);
		const current = bucket.get(balance.date);
		if(current) {
			current.setData(current.fromBalance(balance));
			current.setUpdated(current.getDate(balance));
		} else {
			const newBucket = new BucketClass(this.dscaid, balance.date, this);
			newBucket.setData(newBucket.fromBalance(balance));
			newBucket.setUpdated(newBucket.getDate(balance));
			bucket.set(balance.date, newBucket as any);
		}
	}

	public updateBalance(balance: FpApi.Resource.Duty.ScheduleBalance): void {
		this.superUpdateBalance("schedule", balance);
		this.superUpdateBalance("checkIn", balance);
		this.superUpdateBalance("custom", balance);
		this.superUpdateBalance("premium", balance);
	}

	public superUpdateBalance(type: keyof typeof this.bucketMap, balance: FpApi.Resource.Duty.ScheduleBalance): void {
		this.ensureBucket(balance.date, type);
		const bucket = this.bucketMap[type];
		const current = bucket.get(balance.date);
		if(current) {
			current.updateBalance(balance);
		} else {
			const newBucket = new this.bucketClassMap[type](this.dscaid, balance.date, this);
			newBucket.updateBalance(balance);
			bucket.set(balance.date, newBucket as any);
		}
	}

	public query = <T extends BalanceKeys>(type: T, from: number, to: number, params: QueryParams[T]): ScheduleBalanceResponse => {
		switch(type) {
			case "schedule": {
				const typedParams = params as ScheduleQueryParams;
				return this.querySchedule({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e))[0];
			}
			case "checkIn": {
				const typedParams = params as CheckInQueryParams;
				return this.queryCheckIn({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e))[0];
			}
			case "custom": {
				const typedParams = params as CustomQueryParams;
				return this.queryCustom({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e))[0];
			}
			case "premium": {
				const typedParams = params as PremiumQueryParams;
				return this.queryPremium({ from, to }, castArray(typedParams)).map(e => ScheduleBalanceResponse.fromObject(e))[0];
			}
		}
	}

	public add = <T extends BalanceKeys>(type: T, from: number, params: Prettify<Required<UnFlattenArrayKeys<QueryParams[T]>>>, value: FpApi.Resource.Duty.ScheduleBalanceObject): void => {
		value = value instanceof ScheduleBalanceResponse ? value : ScheduleBalanceResponse.fromObject(value);
		const bucketContainer = this.bucketMap[type];
		const BucketClass = this.bucketClassMap[type];
		const key = ScheduleBalanceContactBucket.makeKey(type as any, params);
		const bucket = bucketContainer.get(from);
		if(bucket) {
			bucket.addValue(key as any, value);
		} else {
			const newBucket = new BucketClass(this.dscaid, from, this);
			newBucket.addValue(key as any, value);
			bucketContainer.set(from, newBucket as any);
		}
	}
	public static makeKey = <T extends BalanceKeys>(type: T, params: UnFlattenArrayKeys<QueryParams[T]>): T extends "schedule" ? ScheduleQuery : T extends "checkIn" ? CheckInQuery : T extends "premium" ? PremiumQuery : CustomQuery => {
		switch(type) {
			case "schedule": {
				const typedParams = params as UnFlattenArrayKeys<ScheduleQueryParams>;
				return `${typedParams.status ?? "*"},${typedParams.type ?? "*"},${typedParams.dscatid ?? "*"},${(typedParams.checkedIn as unknown as string) ?? "*"}` as ScheduleQuery as any;
			}
			case "checkIn": {
				const typedParams = params as UnFlattenArrayKeys<CheckInQueryParams>;
				return `${typedParams.dscatid ?? "*"}` as any;
			}
			case "custom": {
				const typedParams = params as UnFlattenArrayKeys<CustomQueryParams>;
				return `${typedParams.key ?? "*"}` as any;
			}
			case "premium": {
				const typedParams = params as UnFlattenArrayKeys<PremiumQueryParams>;
				return `${typedParams.dscatid ?? "*"}` as any;
			}
		}
	}

	private querySchedule(range: BaseParams, params: ScheduleQueryParams[]): {
		cnt: number;
		min: number;
		day: number;
		dur: number;
	}[]  {
		const responses = [];

		for(const param of params) {
			const response = {
				cnt: 0,
				min: 0,
				day: 0,
				dur: 0,
			};
			responses.push(response);
			const keys = param.dscatid?.map(e => ScheduleBalanceContactBucket.makeKey("schedule", {
				...param,
				dscatid: e,
			})) || [ ScheduleBalanceContactBucket.makeKey("schedule", {
				...param,
				dscatid: undefined,
			}) ];
			for(let i = range.from; i <= range.to; i++) {
				this.ensureBucket(i, "schedule");
				const value = this.bucketMap.schedule.get(i)?.query(keys);
				if(value) {
					response.cnt += (value.cnt ?? 0);
					response.min += (value.min ?? 0);
					response.day += (value.day ?? 0);
					response.dur += (value.dur ?? 0);
				}
			}
		}
		return responses;
	}

	private queryCheckIn(range: BaseParams, params: CheckInQueryParams[]): {
		cnt: number;
		min: number;
		day: number;
		dur: number;
	}[] {


		const queryMapping = new Map<string, CheckInQuery[]>();
		const responseMapping = new Map<string, { cnt: number, min: number, day: number, dur: number }>();
		const order: string[] = [];

		for(const param of params) {
			const paramKey = FpId.new(); // used for later reconstruction
			const response = {
				cnt: 0,
				min: 0,
				day: 0,
				dur: 0,
			};
			// build queries
			const keys = param.dscatid?.map(e => ScheduleBalanceContactBucket.makeKey("checkIn", {
				...param,
				dscatid: e,
			})) || [ ScheduleBalanceContactBucket.makeKey("checkIn", {
				...param,
				dscatid: undefined,
			}) ];
			order.push(paramKey);
			queryMapping.set(paramKey, keys);
			responseMapping.set(paramKey, response);
		}

		for(let i = range.from; i <= range.to; i++) {
			this.ensureBucket(i, "checkIn");
			const value = this.bucketMap.checkIn.get(i)?.queryMap(queryMapping);
			if(!value) continue;
			if(value.size === 0) continue;
			for(const [ key, response ] of value) {
				const returnValue = responseMapping.get(key);
				returnValue.cnt += (response.cnt ?? 0);
				returnValue.min += (response.min ?? 0);
				if("day" in response)
					returnValue.day += (+(response.day ?? 0));
				returnValue.dur += (response.dur ?? 0);
			}
		}
		return order.map(e => responseMapping.get(e) ?? BalanceBucket.getEmpty());
	}

	private queryCustom(range: BaseParams, params: CustomQueryParams[]): {
		cnt: number;
		min: number;
		day: number;
		dur: number;
	}[] {
		const responses = [];

		for(const param of params) {
			const response = {
				cnt: 0,
				min: 0,
				day: 0,
				dur: 0,
			};
			responses.push(response);
			const keys = [ ScheduleBalanceContactBucket.makeKey("custom", {
				...param,
			}) ];
			for(let i = range.from; i <= range.to; i++) {
				this.ensureBucket(i, "custom");
				const value = this.bucketMap.custom.get(i)?.query(keys);
				if(value) {
					response.cnt += (value.cnt ?? 0);
					response.min += (value.min ?? 0);
					response.day += (value.day ?? 0);
					response.dur += (value.dur ?? 0);
				}
			}
		}
		return responses;
	}

	private queryPremium(range: BaseParams, params: PremiumQueryParams[]): {
		cnt: number;
		min: number;
		day: number;
		dur: number;
	}[] {
		const queryMapping = new Map<string, PremiumQuery[]>();
		const responseMapping = new Map<string, { cnt: number, min: number, day: number, dur: number }>();
		const order: string[] = [];

		for(const param of params) {
			const paramKey = FpId.new(); // used for later reconstruction
			const response = {
				cnt: 0,
				min: 0,
				day: 0,
				dur: 0,
			};
			// build queries
			const keys = param.dscatid?.map(e => ScheduleBalanceContactBucket.makeKey("premium", {
				...param,
				dscatid: e,
			})) || [ ScheduleBalanceContactBucket.makeKey("premium", {
				...param,
				dscatid: undefined,
			}) ];
			order.push(paramKey);
			queryMapping.set(paramKey, keys);
			responseMapping.set(paramKey, response);
		}

		for(let i = range.from; i <= range.to; i++) {
			this.ensureBucket(i, "premium");
			const value = this.bucketMap.premium.get(i)?.queryMap(queryMapping);
			if(!value) continue;
			if(value.size === 0) continue;
			for(const [ key, response ] of value) {
				const returnValue = responseMapping.get(key);
				returnValue.cnt += (response.cnt ?? 0);
				returnValue.min += (response.min ?? 0);
				if("day" in response)
					returnValue.day += (+(response.day ?? 0));
				returnValue.dur += (response.dur ?? 0);
			}
		}
		return order.map(e => responseMapping.get(e) ?? BalanceBucket.getEmpty());
	}
}
