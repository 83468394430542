import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateTime } from "luxon";
import React, { useImperativeHandle } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTimezone } from "../../../Components/Format/Context";
import { UiContext } from "../../../FpWebUiProvider";
import { Button, Flex, FlexItem, useFieldHook } from "../../../index";
import { useOutsideAlerter } from "../../../lib";
import { Input } from "../../Input";
import { Overlay, Wrapper } from "../util";
import { DatePicker } from "./DatePicker";
import { toDateTime } from "./util";
function translateFromFormat(input) {
    let mask = input;
    // luxon uses small letters for these
    mask = mask.replace(/Y/g, "y");
    mask = mask.replace(/D/g, "d");
    // single d/m accept both single digits or padded digits
    mask = mask.replace(/dd/g, "d");
    mask = mask.replace(/MM/g, "M");
    // "yy" accepts either 2 or 4 digits
    mask = mask.replace(/yyyy/g, "yy");
    return mask;
}
function translateToFormat(input) {
    let mask = input;
    // luxon uses small letters for these
    mask = mask.replace(/Y/g, "y");
    mask = mask.replace(/D/g, "d");
    return mask;
}
const InternalDateInput = React.forwardRef(function InternalDateInput(props, ref) {
    const minDate = "1900-01-01T00:00:00.000Z";
    const [show, setShow] = React.useState(false);
    const wrapperRef = React.useRef(null);
    useOutsideAlerter(wrapperRef, () => setShow(false));
    const inputRef = React.useRef();
    useImperativeHandle(ref, () => inputRef.current);
    const { formatter } = React.useContext(UiContext);
    const maskFromFormat = translateFromFormat(formatter.options.date.short);
    const maskToFormat = translateToFormat(formatter.options.date.short);
    const tz = useTimezone(props.tz);
    const minimumDateTime = toDateTime(props.min ? props.min : minDate, tz);
    const maximumDateTime = toDateTime(props.max, tz);
    const [value, setInternalValue] = React.useState(props.defaultValue ? toDateTime(props.defaultValue, tz) : toDateTime(props.value, tz));
    React.useEffect(() => {
        if (props.value)
            setInternalValue(toDateTime(props.value, tz));
        else
            setInternalValue(null);
    }, [props.value, tz]);
    React.useEffect(() => {
        if (props.defaultValue)
            setInternalValue(toDateTime(props.defaultValue, tz));
    }, [props.defaultValue]);
    const onChange = (date) => {
        if (props.value && props.defaultValue) {
            console.error("DateInput: You can't use both `value` and `defaultValue` props at the same time.");
        }
        if (props.onChange) {
            props.onChange(date);
        }
        if (!props.value)
            setInternalValue(date);
    };
    /** `string` value of the internal <input> field */
    const [inputString, setInputString] = React.useState(value ? value.toFormat(maskToFormat) : "");
    React.useEffect(() => {
        if (document.activeElement === inputRef.current) {
            // don't update if user has focus
            return;
        }
        if (value?.isValid) {
            setInputString(value ? value.toFormat(maskToFormat) : "");
        }
        else {
            setInputString("");
        }
    }, [value?.toISO()]);
    function triggerChange() {
        if (props.clearable && !inputString) {
            onChange(null);
            return;
        }
        let date = parse(inputString);
        // ignore invalid inputs
        if (date?.isValid !== true)
            return;
        if (date == null)
            return;
        if (minimumDateTime != null && date.hasSame(minimumDateTime, "day"))
            date = DateTime.max(minimumDateTime, date);
        else if (minimumDateTime != null && date < minimumDateTime)
            return;
        if (maximumDateTime != null && date.hasSame(maximumDateTime, "day"))
            date = DateTime.min(maximumDateTime, date);
        else if (maximumDateTime != null && date > maximumDateTime)
            return;
        date = date.startOf("minute");
        // don't trigger `onChange` if value is the same, would cause
        // - unnecessary rendering
        // - infinite rerender loop
        if (value != null && date != null && date.hasSame(value, "millisecond"))
            return;
        if (value != null && date.hasSame(value, "minute"))
            return;
        // report change
        onChange?.(date);
    }
    function parse(input) {
        input = input === "1901-01-01" ? undefined : input;
        let out = DateTime.fromFormat(input, maskFromFormat, { zone: tz });
        if (out.isValid && value != null) {
            // try to keep the time if we have an input value
            out = out.set({
                hour: value.hour,
                minute: value.minute,
                second: value.second,
                millisecond: value.millisecond,
            }).startOf("minute");
        }
        return out;
    }
    return React.createElement(Wrapper, { ref: wrapperRef, style: { width: "100%", ...props.wrapperStyle } },
        React.createElement(Flex, null,
            React.createElement(FlexItem, { grow: true },
                React.createElement(props.as, { "aria-label": props["aria-label"] || props["data-testid"], role: props.role || "dialog", ref: inputRef, disabled: props.disabled, inputMode: "numeric", onFocus: () => setShow(true), value: inputString, onChange: (ev) => {
                        setInputString(ev.target.value);
                    }, onKeyDown: (ev) => {
                        // if tab pressed => hide
                        if (ev.keyCode == 9) {
                            setShow(false);
                        }
                    }, tabIndex: props.tabIndex, placeholder: props.placeholder ?? formatter.options.date.short, onBlur: (e) => {
                        triggerChange();
                        // reset to proper value we are supposed to display (`props.value`) after use blurs field
                        setInputString(value ? value.toFormat(maskToFormat) : "");
                    }, className: props.inputClassName, bootstrap: props.bootstrap, style: {
                        textAlign: "right",
                        ...props.style,
                    } })),
            React.createElement(FlexItem, { shrink: true }, props.isClearable &&
                React.createElement(Button, { variant: "link", size: "sm", onClick: () => {
                        onChange?.(null);
                        setInputString("");
                    } },
                    React.createElement(FontAwesomeIcon, { icon: faTimes })))),
        show && React.createElement(Overlay, { left: props.left },
            React.createElement(DatePicker, { ...props, value: value, tz: tz, onChange: (date) => {
                    onChange?.(date.startOf("minute"));
                    if (props.closeOnChange)
                        setShow(false);
                } })),
        props.name ? React.createElement("input", { type: "hidden", value: value?.toISO() ?? "", name: props.name }) : null);
});
InternalDateInput.defaultProps = {
    as: Input,
};
const DateInputHook = (props) => {
    const { name } = useFieldHook() ?? {};
    const { control } = useFormContext();
    const realName = props.name || name;
    return React.createElement(Controller, { name: realName, control: control, render: ({ field }) => React.createElement(InternalDateInput, { ...props, value: field.value, onChange: (date) => {
                if (date) {
                    if (props.handleOnChange) {
                        props.handleOnChange(date);
                    }
                    else {
                        field.onChange(date.startOf("minute")?.toISO()); // start of minute to produce consistent results
                        props.onChange?.(date);
                    }
                }
                else {
                    field.onChange(null);
                }
            } }) });
};
export const DateInput = Object.assign(InternalDateInput, {
    Hook: DateInputHook,
});
