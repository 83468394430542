import { faChevronUp, faChevronDown, faTimes, faTrash, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTheme } from "styled-components";
import { Button } from "../Button";
import { Flex, FlexItem } from "../Flex";
import { Panel } from "../Panel/Panel";
export function colors(number = 0, baseHue = 207) {
    return `hsl(${(baseHue + (number * 103)) % 360} 100% 35%)`;
}
export const ArrayForm = ({ canDelete = true, canChangeSorting = true, ...props }) => {
    const theme = useTheme();
    return (React.createElement(Panel.Elevation, { style: { padding: "5px 5px 3px", borderTop: `4px solid ${colors(props.idx)}` } },
        React.createElement(Flex, { row: true, gap: "5px" },
            React.createElement(Flex, { column: true, style: { width: props.dragable ? undefined : "100%" } },
                React.createElement(Flex, { column: true, gap: "1em", grow: true }, props.children),
                (props.footer) && React.createElement(Flex, { style: { margin: "7px 0", padding: "0 4px", backgroundColor: "#eee" }, justifyContent: "space-between" },
                    React.createElement(Flex, { justifyContent: "space-between", gap: ".5em", style: { width: "100%" } },
                        props.footer,
                        React.createElement(Flex, { gap: ".5em" },
                            canDelete && React.createElement(MiniDelConf, { handleDelete: props.remove }),
                            canChangeSorting && React.createElement(Flex, { column: true, style: { order: 11 } },
                                React.createElement(Button, { disabled: props.isFirst, size: "xs", variant: "link", outline: true, borderless: true, style: { fontSize: ".75rem" } },
                                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faChevronUp })),
                                React.createElement(Button, { disabled: props.isLast, size: "xs", variant: "link", outline: true, borderless: true, style: { fontSize: ".75rem" } },
                                    React.createElement(FontAwesomeIcon, { fixedWidth: true, icon: faChevronDown }))))))),
            (props.dragable) && React.createElement(Flex, { grow: true, column: true, justifyContent: "space-between", style: { borderLeft: `1px solid ${theme.defaults.borderColor}`, marginTop: "-5px", marginBottom: "-5px", marginRight: "-5px" } },
                React.createElement(FlexItem, null),
                React.createElement(FlexItem, null, "H"),
                React.createElement(FlexItem, null)))));
};
const MiniDelConf = ({ handleDelete }) => {
    const [del, setDel] = React.useState(false);
    if (del === false)
        return React.createElement(Button, { size: "sm", outline: true, borderless: true, variant: "danger", onClick: () => setDel(true), style: { order: 10 } },
            React.createElement(FontAwesomeIcon, { icon: faTrash }));
    return React.createElement(Flex, null,
        React.createElement(Button, { size: "sm", outline: true, borderless: true, variant: "danger", onClick: () => {
                handleDelete();
                setDel(false);
            }, style: { order: 10 } },
            React.createElement(FontAwesomeIcon, { icon: faCheck })),
        React.createElement(Button, { size: "sm", outline: true, borderless: true, variant: "danger", onClick: () => setDel(false), style: { order: 10 } },
            React.createElement(FontAwesomeIcon, { icon: faTimes })));
};
