import { FpDirClientState } from "@tcs-rliess/fp-core";
import { DateTime } from "luxon";

import { FleetplanApp } from "../FleetplanApp";

export class ContactStateUtil {
	public static async getId(app: FleetplanApp, dscaid: number): Promise<FpDirClientState> {
		const state = await app.store.contactState.getId(dscaid);

		return this.cleanup(state);
	}

	public static async getIdList(app: FleetplanApp, dscaidList: number[]): Promise<Map<number, FpDirClientState>> {
		const stateList = await app.store.contactState.getIdList(dscaidList);
		const result = new Map<number, FpDirClientState>();

		for (const state of stateList) {
			result.set(state.id, this.cleanup(state));
		}

		return result;
	}

	public static cleanup(state: FpDirClientState): FpDirClientState {
		return {
			...state,
			resource_checkin: this.resourceCheckIn(state),
		};
	}

	/**
	 * Check which values are valid, and drops when if not valid anymore
	 * @param state contact state
	 * @returns cleaned up object
	 */
	public static resourceCheckIn(state: FpDirClientState): FpDirClientState["resource_checkin"] {
		const now = DateTime.local();
		const date = DateTime.fromISO(state.resource_checkin.date);
		const eow = DateTime.fromISO(state.resource_checkin.eow);

		return {
			date: state.resource_checkin.date,
			eow: state.resource_checkin.eow,

			y: date.hasSame(now, "year") ? state.resource_checkin.y : undefined,
			m: date.hasSame(now, "month") ? state.resource_checkin.m : undefined,
			d: date.hasSame(now, "day") ? state.resource_checkin.d : undefined,
			ds: date.hasSame(now, "day") ? state.resource_checkin.ds : undefined,
			w: now <= eow ? state.resource_checkin.w : undefined,
		};
	}
}