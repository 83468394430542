import { CategoryData, DirectoryMemberData, DirectoryNodeData, FpApi, FpDirClient, FpDirMember, FpDirNode, HazardNodeData, TreeUtil } from "@tcs-rliess/fp-core";
import EventEmitter from "events";
import { observable } from "mobx";

import { FleetplanApp } from "../../../FleetplanApp";
import { isMatch } from "lodash";

type TYPE_NODE = {
	directory: DirectoryNodeData;
	category: CategoryData;
	certificate: unknown;
	document: unknown;
	hazard: HazardNodeData;
}

type TYPE_MEMBER = {
	directory: DirectoryMemberData;
	category: never;
	certificate: never;
	document: never;
	hazard: never;
}

export class DirectoryStore<NAME extends keyof FpApi.Customer.Customer["fpdir"]> extends EventEmitter {
	protected app: FleetplanApp;
	private fpDirClient = new FpDirClient({ use: "DirectoryStore" });

	public readonly name: NAME;

	@observable.ref
	private tree: TreeUtil<FpDirNode<TYPE_NODE[NAME]>, number>;

	@observable.ref
	private members: Array<FpDirMember<TYPE_MEMBER[NAME]>>;
	private membersByResource: Map<string, Array<FpDirMember<TYPE_MEMBER[NAME]>>>;

	constructor(app: FleetplanApp, name: NAME) {
		super();

		this.app = app;
		this.name = name;
	}

	public getTree(): TreeUtil<FpDirNode<TYPE_NODE[NAME]>, number> {
		return this.tree;
	}

	public getMembers(): FpDirMember<TYPE_MEMBER[NAME]>[] {
		return this.members;
	}

	public getMembersByResource(linkType: string, linkId: string | number): FpDirMember<TYPE_MEMBER[NAME]>[] {
		const key = `${linkType}:${linkId}`;
		return this.membersByResource.get(key) ?? [];
	}

	public search(filter: { grp?: number, loc?: number, pos?: number, sec?: number }): FpDirMember<TYPE_MEMBER[NAME]>[] {
		return this.members.filter(member => {
			return isMatch(member, filter);
		});
	}

	public async reload(): Promise<void> {
		const dirId = this.app.customer.fpdir[this.name];

		const reqTree = await this.fpDirClient.treeGet(this.app.dscid, dirId);
		this.tree = TreeUtil.fromDirectoryNodes(reqTree.tree);

		const reqMember = await this.fpDirClient.memberGet<TYPE_MEMBER[NAME]>(this.app.dscid, dirId);
		this.members = reqMember.rows;

		this.membersByResource = new Map();
		this.members.forEach(member => {
			const key = `${member.linktype}:${member.linkid}`;
			const r = this.membersByResource.get(key);

			if (r == null) {
				this.membersByResource.set(key, [ member ]);
			} else {
				r.push(member);
			}
		});

		this.emit("reload", this.name);
	}

	public async reloadMembers(): Promise<void> {
		const dirId = this.app.customer.fpdir[this.name];
		const reqMember = await this.fpDirClient.memberGet<TYPE_MEMBER[NAME]>(this.app.dscid, dirId);
		this.members = reqMember.rows;

		this.membersByResource = new Map();
		this.members.forEach(member => {
			const key = `${member.linktype}:${member.linkid}`;
			const r = this.membersByResource.get(key);

			if (r == null) {
				this.membersByResource.set(key, [ member ]);
			} else {
				r.push(member);
			}
		});
		this.emit("reload-members", this.name);
	}
}
